import React from "react";
import "./Contact.scss";

export default function Contact() {
  return (
    <div className="contact-us">
      <div className="row">
        <div className="key">Indent Section :</div>
        <div className="value">+91-8767543432</div>
      </div>
      <div className="row">
        <div className="key">Route Supervisor :</div>
        <div className="value">+91-7565754342</div>
      </div>
      <div className="row">
        <div className="key">Assistant Manager :</div>
        <div className="value">+91-8767543432</div>
      </div>
      <div className="row">
        <div className="key">Deputy Manager :</div>
        <div className="value">+91-7765435432</div>
      </div>
      <div className="row">
        <div className="key">App developer :</div>
        <div className="value">Siri Technologies Mysore 8884402530</div>
      </div>
    </div>
  );
}
