import axios from "axios";

export const getRequest = async (url, params = null) => {
  try {
    const response = await axios.get(url, params);
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const putRequest = async (url, data) => {
  try {
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const patchRequest = async (url, data) => {
  try {
    const response = await axios.patch(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteRequest = async (url) => {
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;
