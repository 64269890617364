import { Form, InputNumber } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ManualPayment() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));

  const handleSubmit = async (values) => {
    sessionStorage.setItem("initialPayment", true);
    let amount = values.amount;
    form.resetFields();
    return navigate("/payment", {
      state: { amount: amount },
      replace: true,
    });
  };

  return creditLimit[0].PAY_MODE === "C" ? (
    <div style={{ marginTop: 20, fontWeight: 600 }}>
      You are not authorized to make online transactions.
    </div>
  ) : (
    <>
      <div className="page-header">Make Payment</div>
      <div
        style={{
          height: "100%",
          display: "flex",
          marginTop: "100px",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Form
          className="manual-payment-form"
          requiredMark={false}
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="amount"
            label={<h3>Enter amount</h3>}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: "Please enter an amount",
              },
              {
                validator: (_, value) => {
                  if (value >= 1) return Promise.resolve();
                  else return Promise.reject("Amount must be greater than 0");
                },
              },
            ]}
          >
            <InputNumber
              addonBefore={
                <string style={{ fontFamily: "sans-serif" }}> &#x20B9;</string>
              }
              style={{ width: "100%" }}
              precision={2}
              controls={false}
            />
          </Form.Item>
        </Form>
        <button className="primary-btn" onClick={() => form.submit()}>
          Pay
        </button>
      </div>
    </>
  );
}
