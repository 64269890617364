import { Empty, Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import ListLoader from "../../re-usables/loaders/ListLoader";
import DownloadReport from "./DownloadReport";

export default function RateChart() {
  let customerId = localStorage.getItem("userId");
  const [rateChartData, setRateChartData] = useState();
  const [loading, setLoading] = useState(true);

  const getChartData = async () => {
    try {
      setLoading(true);
      const res = await getRequest(urls.rateChartData(customerId));
      setRateChartData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const rateChartColumns = [
    { title: "Product", dataIndex: "PACKET_NAME" },
    {
      title: "Basic Rate",
      dataIndex: "BASIC_RATE",
      width: 60,
      align: "center",
    },
    {
      title: "GST Amt",
      dataIndex: "GST",
      width: 65,
      align: "center",
    },
    {
      title: "Rate",

      dataIndex: "RATE",
      width: 60,
      align: "center",
    },
  ];

  useEffect(() => {
    getChartData();
  }, []);

  // if (loading)
  //   return (
  //     <div style={{ height: "calc(100vh - 281px)", overflowY: "hidden" }}>
  //       <ListLoader />
  //     </div>
  //   );

  return (
    <div>
      <div className="page-header">
        Rate Chart{" "}
        {rateChartData && rateChartData.length && !loading ? (
          <DownloadReport
            columns={rateChartColumns}
            data={rateChartData}
            reportName="Rate Chart"
          />
        ) : null}
      </div>

      <Table
        dataSource={loading ? [] : rateChartData}
        locale={{
          emptyText: loading ? <ListLoader /> : <Empty />,
        }}
        columns={rateChartColumns}
        bordered
        scroll={{ y: "calc(100vh - 380px)" }}
        pagination={false}
        style={{ marginTop: 15 }}
      />
    </div>
  );
}
